<template>
  <div id="containtSales">
    <div class="grid md:grid-cols-12 gap-6">
      <div class="md:col-span-8 xl:col-span-9">
        <div class="flex flex-wrap gap-4 mb-3">
          <button
            class="px-2 py-1 bg-blue-100 text-blue-500 font-semibold text-xs rounded-md active:scale-95 items-center flex hover:bg-blue-500 hover:text-white"
            @click="showAddProduct"
          >
            <font-awesome-icon icon="fa-solid fa-store" class="mr-1" />
            Productos
          </button>
          <button
            class="px-2 py-1 bg-green-100 text-green-700 font-semibold text-xs rounded-md active:scale-95 hover:bg-green-500 hover:text-white"
            @click="showAppDelivery"
          >
            <font-awesome-icon icon="fa-solid fa-truck" class="mr-1" /> Delivery
          </button>
          <button
            class="px-2 py-1 bg-blue-100 text-blue-500 font-semibold text-xs rounded-md active:scale-95 hover:bg-blue-500 hover:text-white"
            @click="showInsurer"
            v-show="stateAccesInsurer"
          >
            <font-awesome-icon icon="fa-solid fa-building" class="mr-1" />
            Aseguradoras
          </button>
          <button
            class="px-2 py-1 bg-gray-100 text-gray-600 font-semibold text-xs rounded-md active:scale-95 hover:bg-gray-500 hover:text-white"
            @click="showCreditNote"
          >
            <font-awesome-icon icon="fa-solid fa-file" class="mr-1" />
            Nota de crédito
          </button>
          <button
            class="px-2 py-1 bg-blue-100 text-blue-500 font-semibold text-xs rounded-md active:scale-95 items-center flex hover:bg-blue-500 hover:text-white"
            @click="showSalesHold"
          >
            <font-awesome-icon icon="fa-solid fa-cart-shopping" class="mr-1" />
            Factura Hold
          </button>
          <button
            class="px-2 py-1 bg-orange-100 text-orange-600 font-semibold text-xs rounded-md active:scale-95 hover:bg-orange-500 hover:text-white"
            @click="showTemporarySales"
          >
            <font-awesome-icon icon="fa-solid fa-computer-mouse" class="mr-1" />
            Ventas temporales
          </button>
          <button
            class="px-2 py-1 bg-blue-100 text-blue-500 font-semibold text-xs rounded-md active:scale-95 hover:bg-blue-500 hover:text-white"
            @click="showQuotes"
          >
            <font-awesome-icon icon="fa-solid fa-folder-open" class="mr-1" />
            Cotizaciones
          </button>
        </div>
        <div class="mb-3">
          <SearchCustomer
            @send-data="getDataCustomer"
            :stateInfo="stateShowInfoCustomer"
          />
        </div>
        <div
          class="bg-blue-50 px-3 rounded-md py-3 mb-3"
          v-if="stateTemporarySales"
        >
          <SearchTemporarySales @send-data="getOrder" />
        </div>
        <div class="bg-blue-50 px-3 rounded-md py-3 mb-3" v-if="stateQuotes">
          <SearchQuotes @send-data="getOrder" />
        </div>
        <div class="bg-blue-50 px-3 rounded-md py-3 mb-3" v-if="stateSalesHold">
          <SearchSalesHold @send-data="getOrder" />
        </div>
        <div
          class="bg-blue-50 px-3 rounded-md py-3 mb-3"
          v-if="stateAppDelivery"
        >
          <SearchAppDelivery @send-data="getDataAppDelivery" />
        </div>
        <div class="bg-blue-50 px-3 rounded-md py-3 mb-3" v-if="stateInsurer">
          <SearchInsurer @send-data="getInsurer" />
        </div>
        <div
          class="bg-blue-50 px-3 rounded-md py-3 mb-3"
          v-if="stateCreditNote"
        >
          <SearchCreditNote @send-data="getCreditNote" />
        </div>
        <div>
          <div
            class="py-3 bg-blue-50 px-3 rounded-md grid md:grid-cols-12 gap-4"
          >
            <SearchProduct
              @send-data="addProducts"
              :typeVoucher="nameVoucher"
              :sellWithCustomer="infoCustomer.sell_with"
            />
            <div class="col-span-3 flex items-center justify-end">
              <button
                class="bg-red-100 rounded-md text-red-600 text-sm px-3 py-1 h-full font-semibold hover:bg-red-500 hover:text-white active:scale-95"
                @click="saveTemporarySales"
              >
                TEMP
              </button>
            </div>
          </div>
          <!-- detalle de la orden de compra -->
          <div
            class="overflow-x-auto table-d md:max-w-full max-h-[calc(100vh-210px)] min-h-[calc(100vh-470px)]"
          >
            <table class="table-auto w-full">
              <TableHead
                :headers="header"
                class="sticky top-0 [&>tr>th]:text-[12px]"
              />
              <LoadingTables v-if="loading" :columns="9" />
              <tbody
                class="text-gray-500 text-left border-t-[1px]"
                v-else
                id="dataTable"
              >
                <tr
                  class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50 td-focus"
                  v-for="(product, i) in arrayOfProducts"
                  :key="i"
                >
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap"
                    :class="stateAccesCheck ? 'table-cell' : 'hidden'"
                  >
                    <input
                      type="checkbox"
                      class="cursor-pointer w-4 h-4"
                      @change="selectProduct(product)"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap"
                  >
                    {{ product.productName }}
                  </td>
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="number"
                      min="1"
                      :value="product.quantity"
                      class="bg-white text-gray-500 outline-blue-500 py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                      @blur="
                        handleQuantityChange(i, $event, product.igvApplied)
                      "
                      @keyup.enter="
                        handleQuantityChange(i, $event, product.igvApplied)
                      "
                    />
                  </td>
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <select
                      class="border-2 bg-gray-50 py-1 text-xs px-3 text-gray-600 cursor-pointer rounded-lg mr-3 font-semibold max-w-[120px] focus:outline-double focus:outline-2 focus:outline-blue-500"
                      @change="
                        handleUnitMeasureChange(i, $event, product.igvApplied)
                      "
                    >
                      <option
                        :value="
                          unit.precio +
                          '|' +
                          unit.cantidad +
                          '|' +
                          unit.unidad_de_medida
                        "
                        v-for="(unit, index) in product.units"
                        :key="index"
                        :selected="
                          unit.unidad_de_medida === product.unitMeasure
                        "
                      >
                        {{ unit.unidad_de_medida }}
                      </option>
                    </select>
                  </td>
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <select
                      @blur="handlePrices(i, $event, product)"
                      @input="handlePrices(i, $event, product)"
                      class="border-2 bg-gray-50 py-1 text-xs px-3 text-gray-600 cursor-pointer rounded-lg mr-3 font-semibold focus:outline-double focus:outline-2 focus:outline-blue-500"
                      v-model="product.priceSelectedForTheCustomer"
                    >
                      <option value="1" selected>PRECIO 1</option>
                      <option
                        value="2"
                        v-if="product.showBTNPriceTwo && usePriceTwo"
                      >
                        PRECIO 2
                      </option>
                      <option
                        value="3"
                        v-if="product.showBTNPriceThree && usePriceThree"
                      >
                        PRECIO 3
                      </option>
                    </select>
                  </td>
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <!--{{ money() }} {{ format(product.salePrice) }}-->
                    <span v-if="!stateModifiPrice"
                      >{{ money() }} {{ format(product.salePrice) }}</span
                    >
                    <input
                      v-else
                      type="number"
                      min="1"
                      :value="product.salePrice"
                      class="bg-white text-orange-500 outline-none py-1 px-1 text-xs max-w-[70px] rounded-md border-2 border-blue-100 text-center"
                      @blur="
                        handleUnitPriceChange(
                          i,
                          $event,
                          product.igvApplied,
                          product.shoppingPrice,
                          product.salePrice
                        )
                      "
                      @keyup.enter="
                        handleUnitPriceChange(
                          i,
                          $event,
                          product.igvApplied,
                          product.shoppingPrice,
                          product.salePrice
                        )
                      "
                    />
                  </td>
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase font-bold"
                  >
                    {{ money() }} {{ format(product.total) }}
                  </td>
                  <!-- <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="number"
                      min="1"
                      :value="product.discount"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                      @blur="
                        handleDiscountChange(i, $event, product.igvApplied)
                      "
                      @keyup.enter="
                        handleDiscountChange(i, $event, product.igvApplied)
                      "
                    />
                  </td> -->
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                    :class="{ 'w-0 hidden': !stateAccesInsurer }"
                  >
                    <input
                      type="number"
                      min="1"
                      :value="product.insuranceDiscount"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                      @blur="handleInsuranceDiscountChange(i, $event)"
                      @keyup.enter="handleInsuranceDiscountChange(i, $event)"
                    />
                  </td>
                  <!-- <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <span
                      v-if="
                        calculateOffertExpired(
                          product.vencimiento_descuento
                        ) === true
                      "
                    ></span>
                    <input
                      v-else
                      type="checkbox"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                      @change="
                        handlePriceForDiscount(i, $event, product.igvApplied)
                      "
                    />
                  </td> -->
                  <td
                    class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                    :class="stateAccesPSerie ? 'table-cell' : 'hidden'"
                  >
                    <input
                      type="text"
                      :value="product.productSerie"
                      class="bg-white text-gray-500 outline-none py-1 px-1 rounded-md border-2 border-blue-100 text-center"
                      @blur="handleProductSerie(i, $event)"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                  >
                    <div class="flex justify-end">
                      <button
                        class="bg-red-100 text-red-500 hover:text-white p-2 w-8 h-8 text-sm active:scale-105 hover:bg-red-500 flex items-center justify-center rounded-md"
                        @click="deleteRecordFromTable(i)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-trash"
                          class="w-3 h-3"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <ComponentForTotalDetails
            :iva="dataForm.igv"
            :subTotal="dataForm.subTotal"
            :total="dataForm.total"
            :otherDiscounts="dataForm.otherDiscounts"
            :totalCreditNote="dataForm.totalCreditNote"
            :totalCreditNoteDiference="dataForm.totalCreditNoteDiference"
            :totalWhitDiscount="dataForm.totalWhitDiscount"
            :totalPayableByCustomer="dataForm.totalPayableByCustomer"
            :totalPayableByInsurer="dataForm.totalPayableByInsurer"
            :insurerBussinesName="infoInsurer.bussinesName"
          />
        </div>
        <div
          class="px-3 py-3 rounded-md bg-blue-50 border border-blue-100 mt-3 md:grid md:grid-cols-2 md:gap-4"
          v-show="stateForCreditToSale"
        >
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-blue-500 dark:text-white"
            >
              Abono inicial
            </span>
            <input
              type="number"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.initialFee"
              placeholder="Abono inicial..."
              @keydown.enter.prevent
              min="0"
            />
          </label>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-blue-500 dark:text-white"
            >
              Fecha de pago
            </span>
            <input
              type="date"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.paymentDate"
              @keydown.enter.prevent
            />
          </label>
        </div>
      </div>
      <div
        class="md:col-span-4 xl:col-span-3 px-3 py-3 rounded-md border-2 shadow-md shadow-blue-100 border-blue-50 space-y-4"
      >
        <div
          class="md:max-h-[45vh] 2xl:max-h-full overflow-y-auto md:pb-10 pr-2 2xl:p-0 scroll-options-sales"
        >
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Mensajero
            </span>
            <SearchUserCharge @send-data="handeCharge" />
          </label>
          <!-- lista de comprobante -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Comprobante de venta
            </span>
            <VouchersOfSale
              @send-data="getSelectVouchersOfSale"
              :selectOption="typeVoucher"
              :selectOptionForCustomer="infoCustomer.fixed_voucher"
              :key="changeVoucher"
            />
          </label>
          <!-- lista de tipo de ventas -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de venta
            </span>
            <TypeOfSale
              :selectOption="dataForm.idTypeOfSale"
              @send-data="getSelectTypeOfSale"
              :key="counterKeyTypeOfSale"
            />
          </label>
          <!-- lista de tipos de pagos -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de pago
            </span>
            <TypeOfPayment
              :selectOption="dataForm.idTypeOfPayment"
              @send-data="getSelectTypeOfPayment"
              :key="counterKeyTypeOfPayment"
            />
          </label>
          <!-- numero de operación o transacción -->
          <label class="block" v-show="stateForPaymentType">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              N° de operación
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.numberOperation"
              placeholder="N° de operación..."
              @keydown.enter.prevent
              maxlength="30"
            />
          </label>

          <!-- descripción entidad bancaria -->
          <label class="block" v-show="stateForPaymentType">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Entidad bancaria
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.entityBank"
              placeholder="Entidad bancaria..."
              @keydown.enter.prevent
              maxlength="60"
            />
          </label>
          <!-- validez de cotización -->
          <label class="block" v-show="stateForQuotation">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Validez de cotización
            </span>
            <input
              type="date"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.expireQuotation"
              @keydown.enter.prevent
            />
          </label>
          <!-- Comentario adicional -->
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Comentario adicional
            </span>
            <textarea
              name=""
              id=""
              cols="30"
              rows="2"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.comentary"
              placeholder="Comentario adicional..."
              maxlength="100"
            ></textarea>
          </label>
          <!-- Descuento para el usuario -->
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Descuento
            </span>
            <input
              type="number"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.discount"
              placeholder="Comentario adicional..."
              @keydown.enter.prevent
              v-if="!salesDiscountStatus"
              :readonly="!salesDiscountStatus"
              @click="showValidateUserAccess('REALIZAR DESCUENTOS EN VENTA')"
              id="discountSale"
            />
            <input
              type="number"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.discount"
              placeholder="Descuento global..."
              @keydown.enter.prevent
              v-if="salesDiscountStatus"
              @input="applyDiscountToSale"
            />
          </label>
        </div>
        <div
          class="px-3 py-3 rounded-md border-2 border-blue-100 bg-blue-100 grid gap-4"
          v-show="stateInputsThePayment"
        >
          <!-- Importe de pago del cliente -->
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-blue-500 dark:text-white"
            >
              IMPORTE DE PAGO
            </span>
            <input
              type="number"
              class="py-2 px-2 text-sm bg-gray-50 border-blue-500 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.customerAmountPaid"
              placeholder="Importe del cliente"
              @keydown.enter.prevent
              @input="calculteCustomerAmountReturned"
              @click="dataForm.customerAmountPaid = ''"
              id="import_payments"
              title="ALT + D"
            />
          </label>
          <!-- Monto de vuelto que recivira el cliente -->
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-blue-500 dark:text-white"
            >
              DEVUELTA
            </span>
            <input
              type="number"
              class="py-2 px-2 text-sm bg-blue-200 border-blue-200 outline-none rounded-md text-blue-600 w-full border-2 resize-none font-bold"
              v-model="dataForm.customerAmountReturned"
              placeholder="Devuelta"
              @keydown.enter.prevent
              readonly
            />
          </label>
        </div>
        <div class="flex justify-between gap-3">
          <button
            class="bg-red-50 text-red-500 px-3 py-3 font-semibold text-xs rounded-lg hover:shadow-sm active:scale-95 flex items-center justify-center w-22 hover:bg-red-500 hover:text-white"
            @click="cancelSale"
            title="ALT + X"
          >
            <font-awesome-icon icon="fa-solid fa-cancel" class="mr-2" />
            <span>Cancelar</span>
          </button>
          <button
            class="bg-blue-500 text-white px-3 py-3 font-semibold text-xs rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full justify-center"
            @click="insert"
            :disabled="spinner === true"
            title="ALT + S"
          >
            <div class="flex items-center" v-if="spinner">
              <svg class="w-4 h-4 mr-3 animate-spin">
                <use href="../../assets/svg/icon.svg#spinnerForBlue" />
              </svg>
              Registrando venta
            </div>
            <span v-else>Registrar venta</span>
          </button>
        </div>
      </div>
    </div>
    <AddProduct
      @send-data="getAmountAgreed"
      v-if="stateAddProduct"
      :activeModal="showAddProduct"
    />
    <ValidateUserAccess
      v-if="stateShowUserAccess"
      :activeModal="showValidateUserAccess"
      :area="area"
      @send-data="validateAccess"
    />
    <CombinedPayments
      :activeModal="showCombinedPayments"
      @send-data="getCombinedPayment"
      :amount="dataForm.totalWhitDiscount"
      v-if="stateShowCombinedPayment"
    />
  </div>
</template>

<script>
import SearchCustomer from "./Searchs/SearchCustomer.vue";
import SearchTemporarySales from "./Searchs/SearchTemporarySales.vue";
import SearchProduct from "./Searchs/SearchProduct.vue"; // Public components
import SearchSalesHold from "./Searchs/SearchSalesHold.vue";
import SearchAppDelivery from "./Searchs/SearchAppDelivery.vue";
import SearchInsurer from "./Searchs/SearchInsurer.vue";
import SearchQuotes from "./Searchs/SearchQuotes.vue";
import SearchCreditNote from "./Searchs/SearchCreditNote.vue";
// Public components
import TypeOfPayment from "@/components/PublicComponents/TypeOfPayment.vue";
import TypeOfSale from "@/components/PublicComponents/TypeOfSale.vue";
import VouchersOfSale from "@/components/PublicComponents/VouchersOfSale.vue";
import ComponentForTotalDetails from "./Details/ComponentForTotalDetails.vue";
import { nextTick, onMounted, onUnmounted, ref } from "vue";
import { observeElement } from "@/observer";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import { money, format } from "@/publicjs/money";
import { percentage } from "@/publicjs/convertToPercentage";
import { errorActions } from "@/alerts";
import axios from "@/api";
import AddProduct from "@/components/Sales/AddProductsToStore/AddProduct.vue";
import ValidateUserAccess from "@/components/ValidateUserAccess/ValidateUserAccess.vue";
import CombinedPayments from "@/components/Sales/CombinedPayments/CombinedPayments.vue";
import { insertSale } from "@/repository/Sales/Main/InsertSale";
import { insertCustomerOrder } from "@/repository/Sales/Main/InsertCustomerOrder";
import { insertQuotes } from "@/repository/Sales/Main/InsertQuotes";
import { insertTemporarySale } from "@/repository/Sales/Main/InsertTemporarySale";
import SearchUserCharge from "./Searchs/SearchUserCharge.vue";

import {
  referenceNumber,
  updateNumberVoucher,
  updateReferenceNumber,
  voucherSales,
} from "@/publicjs/vouchers";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
  localIVA,
  localMoney,
  localUserName,
} from "@/publicjs/localStorage";

// Print voucher
import { printVoucherOnA4 } from "./PrintVoucher/PrintOnA4";
import { printVoucherOnLetter } from "./PrintVoucher/PrintOnLetter";
import { printVoucherOnMediaLetter } from "./PrintVoucher/PrintOnMediaLetter";
import { printVoucherTicket } from "./PrintVoucher/PrintOnTicket";

//Impresion de comprobantes para cortizaciones
import { printOnA4 } from "@/components/Quotes/PrintVoucher/PrintOnA4";
import { printOnLetter } from "@/components/Quotes/PrintVoucher/PrintOnLetter";
import { printOnMediaLetter } from "@/components/Quotes/PrintVoucher/PrintOnMediaLetter";
import { printOnTicket } from "@/components/Quotes/PrintVoucher/PrintOnTicket";
import calculateOffertExpired from "@/publicjs/calculateOffertExpired";
//Importacion para validar acceso del usuario
import { validateAccessSpecific } from "@/repository/Sales/ValidateAccessEspecific/ValidateAccess";
import columsHidden from "@/publicjs/columsHidden";
import calculatePaymentDate from "@/publicjs/calculatePaymentDate";
export default {
  name: "MainSales",
  components: {
    SearchCustomer,
    SearchTemporarySales,
    SearchProduct,
    SearchSalesHold,
    SearchAppDelivery,
    SearchInsurer,
    SearchQuotes,
    SearchCreditNote,
    TypeOfPayment,
    TypeOfSale,
    VouchersOfSale,
    ComponentForTotalDetails,
    LoadingTables,
    TableHead,
    AddProduct,
    ValidateUserAccess,
    CombinedPayments,
    SearchUserCharge,
  },
  setup() {
    const stateAccesInsurer = ref(false);
    const stateModifiPrice = ref(false);
    const header = ref([]);
    const stateAccesCheck = ref(false);
    const stateAccesPSerie = ref(false);

    const VALIDATEDACCES = ref([]);
    const ACCESS = [
      "ASEGURADORAS MODULO VENTAS",
      "APLICAR PRECIO 2 EN VENTAS",
      "APLICAR PRECIO 3 EN VENTAS",
      "✏ MODIFICAR PRECIO DE VENTA DEL PRODUCTO (VENTAS)",
      "VENTAS TEMPORALES MODULO VENTAS",
      "FACTURAS HOLD MODULO VENTAS",
      "AGREGAR PRODUCTOS DESDE VENTAS",
      "COTIZACIONES MODULO VENTAS",
      "APLICAR NOTA DE CREDITO MODULO VENTAS",
      "ACTIVAR COLUMNA DE CHECK EN LA TABLA VENTAS",
      "ACTIVAR COLUMNA DE SERIE DE PRODUCTO TABLA DE VENTAS",
    ];

    //Estado de componentes
    const stateForSaleType = ref(false);
    const stateForPaymentType = ref(false);
    const stateForQuotation = ref(false);
    const spinner = ref(false);
    const stateTemporarySales = ref(false);
    const stateSalesHold = ref(false);
    const stateAppDelivery = ref(false);
    const loading = ref(false);
    const stateAddProduct = ref(false);
    const stateInsurer = ref(false);
    const salesDiscountStatus = ref(false);
    const stateQuotes = ref(false);
    const stateCreditNote = ref(false);
    const stateShowUserAccess = ref(false);
    const stateShowCombinedPayment = ref(false);
    const area = ref(""); // Para especificar el area a donde va a acceder el usuario
    const limitDiscount = ref(0); // El limite de descuento que podrá hacer el usuario
    const nameVoucher = ref(""); // Sirve para hacer alguna validaciones
    const stateInputsThePayment = ref(true); // Sirve para mostrar o ocultar el monto que pagar el cliente
    const stateShowInfoCustomer = ref(false);
    const stateForCreditToSale = ref(false); //Estado para mostrar configuracion de ventas a credito
    const arraySelectProduct = ref([]);
    const changeVoucher = ref(0);

    const usePriceTwo = ref(false);
    const usePriceThree = ref(false);

    //Datos de la venta en especifico
    const dataForm = ref({
      idUser: 0,
      idStore: 0,
      idTurn: 0,
      idVoucher: 0,
      idTypeOfSale: 0,
      idTypeOfPayment: 0,
      idCreditNote: null,
      total: 0,
      totalWhitDiscount: 0,
      igv: 0,
      subTotal: 0,
      otherDiscounts: 0,
      totalCreditNote: 0,
      totalCreditNoteDiference: 0,
      discount: 0,
      expireQuotation: null,
      comentary: "",
      totalPayableByInsurer: 0,
      totalPayableByCustomer: 0,
      userName: "",
      customerName: "",
      serieVoucher: "",
      numberVoucher: "",
      numberReference: "",
      currencyDescription: "",
      currencyPrefix: "",
      igvApplied: 0,
      retention: 0,
      entityBank: "",
      numberOperation: "",
      cashAmount: 0,
      targetAmount: 0,
      transferAmount: 0,
      chequeAmount: 0,
      customerAmountPaid: 0,
      customerAmountReturned: 0,
      modificationDate: null,
      sunatAnswer: 0,
      state: "",
      paymentDate: null,
      initialFee: 0,
      idCharge: null,
      nameCharge: null,
    });

    /*********************************************************************************
     * Informacion del cliente para hacer validaciones con lo datos.
     * Los datos bienen desde el componente público SearchCustomer
     *********************************************************************************
     */
    const infoCustomer = ref({
      id: 0,
      fullName: null,
      creditLimit: 0,
      maximunDiscount: 0,
      sell_with: 1,
      fixed_voucher: 0,
      paydays: null,
      document_number: 0,
      type: null,
    });
    const getDataCustomer = (data) => {
      infoCustomer.value.id = data.idCustomer;
      infoCustomer.value.fullName = data.searchInput;
      infoCustomer.value.creditLimit = data.creditLimit;
      infoCustomer.value.maximunDiscount = data.maximumDiscount;
      infoCustomer.value.type = data.typeCustomer;
      infoCustomer.value.sell_with = data.sell_with;
      infoCustomer.value.fixed_voucher = data.fixed_voucher;
      infoCustomer.value.paydays = data.paydays;
      stateShowInfoCustomer.value = true;
      dataForm.value.paymentDate = data.paydays !== null ? data.paydays : null;
      changeVoucher.value++;
    };

    /*********************************************************************************
     * Informacion de la APP DELIVERY para aplicar un porcentaje de comisiones a cada producto.
     * Lo datos bienen desde el componente público SearchAppDelivery
     *********************************************************************************
     */
    const getDataAppDelivery = async (data) => {
      if (arrayOfProducts.value.length > 0) {
        await applyCommissionForDelivery(data);
      } else {
        errorActions(
          "Lista de ventas vacía <strong>no podemos aplicar una comision de la empresa asuguradora debido a que no has agregado productos a la lista de ventas</strong>"
        );
        return;
      }
      if (arraySelectProduct.value.length <= 0) {
        errorActions(
          "Por favor selecciona <strong>los productos a los cuales se aplicará el descuento.</strong>"
        );
        return;
      }
    };

    const selectProduct = (data) => {
      const index = arraySelectProduct.value.indexOf(data.idProduct);
      if (index !== -1) {
        arraySelectProduct.value.splice(index, 1);
      } else {
        arraySelectProduct.value.push(data.idProduct);
      }
    };

    /*********************************************************************************
     * Informacion del encargado que llevara el producto
     * Lo datos bienen desde el componente público SearchUserCharge
     *********************************************************************************
     */
    const handeCharge = (data) => {
      dataForm.value.idCharge = data.id;
      dataForm.value.nameCharge = data.name;
    };
    /*********************************************************************************
     * Funcion para aplicar comision a todos los productos de la venta
     * Se aplicara la comision configurada de la empresa de delivery a cada producto
     *********************************************************************************/
    const applyCommissionForDelivery = async (data) => {
      //Recorremos todo el arreglo de producto
      const { commission } = data;
      // arrayOfProducts.value.forEach((element) => {
      //   const igvApplied = element.igvApplied;
      //   const CONFIGURE_IGV = percentage(igvApplied);
      //   const percentageOfCommission = percentage(commission);

      //   const applyCommision = element.salePrice * percentageOfCommission;

      //   element.salePrice += applyCommision;

      //   element.subTotal = parseFloat(
      //     (parseFloat(element.salePrice) / (CONFIGURE_IGV + 1)).toFixed(2)
      //   );
      //   element.igv = parseFloat(
      //     (parseFloat(element.subTotal) * CONFIGURE_IGV).toFixed(2)
      //   );
      //   element.total = parseFloat(
      //     (
      //       parseFloat(element.quantity) * parseFloat(element.salePrice)
      //     ).toFixed(2)
      //   );
      // });
      arrayOfProducts.value.forEach((element) => {
        if (arraySelectProduct.value.includes(element.idProduct)) {
          const igvApplied = element.igvApplied;
          const CONFIGURE_IGV = percentage(igvApplied);

          const applyCommision = element.salePrice * (commission / 100);
          element.salePrice += applyCommision;

          element.subTotal = parseFloat(
            (parseFloat(element.salePrice) / (CONFIGURE_IGV + 1)).toFixed(2)
          );
          element.igv = parseFloat(
            (parseFloat(element.subTotal) * CONFIGURE_IGV).toFixed(2)
          );
          element.total = parseFloat(
            (
              parseFloat(element.quantity) * parseFloat(element.salePrice)
            ).toFixed(2)
          );
        }
      });
      calculateTheTotal(arrayOfProducts.value);
    };

    /*********************************************************************************
     * Informacion la empresa aseguradora
     * Lo datos bienen desde el componente público SearchInsurer
     *********************************************************************************
     */
    const infoInsurer = ref({
      id: 0,
      bussinesName: "",
    });
    const getInsurer = async (data) => {
      if (arrayOfProducts.value.length > 0) {
        const { idInsurer, bussinesName } = data;
        infoInsurer.value.id = idInsurer;
        infoInsurer.value.bussinesName = bussinesName;
        await calculateTheTotalPaymentOfTheInsurerAndCustomer();
      } else {
        errorActions(
          "Lo sentimos no podemos aplicar el descuento por seguro a la venta, debido a que no tienes productos añadidos a la lista de ventas"
        );
      }
    };

    /*********************************************************************************
     * Informacion de la nota de credito seleccionado
     * Lo datos bienen desde el componente público SearchCreditNote
     *********************************************************************************
     */
    const infoCreditNote = ref({
      id: 0,
      amount: 0,
    });
    const getCreditNote = async (data) => {
      if (arrayOfProducts.value.length > 0) {
        const { id, amount } = data;
        dataForm.value.idCreditNote = id;

        //calculamos si la nota de credito es mayor a la venta
        if (amount >= dataForm.value.totalWhitDiscount) {
          const newAmount = amount - dataForm.value.totalWhitDiscount;

          dataForm.value.totalCreditNote = dataForm.value.totalWhitDiscount;
          infoCreditNote.value.amount = newAmount;
          dataForm.value.totalWhitDiscount = 0;
          dataForm.value.totalCreditNoteDiference = 0;
          //Pasamos los datos que biene a los datos de nota de credito de la venta
        } else if (amount < dataForm.value.totalWhitDiscount) {
          const newPrice = dataForm.value.totalWhitDiscount - amount;
          dataForm.value.totalWhitDiscount = newPrice;
          dataForm.value.totalCreditNoteDiference = newPrice;
          //Pasamos los datos que biene a los datos de nota de credito de la venta
          dataForm.value.totalCreditNote = newPrice;

          infoCreditNote.value.amount = 0;
        }
      } else {
        errorActions(
          "Lo sentimos no podemos aplicar la nota de crédito, debido a que no tienes productos añadidos a la lista de ventas"
        );
      }
    };

    /*********************************************************************************
     * Funcion para calcular el monto que debe de pagar la aseguradora y el monto
     * que debe de pagar el cliente segun el descuento aplicado po seguro
     *********************************************************************************
     */
    const calculateTheTotalPaymentOfTheInsurerAndCustomer = async () => {
      dataForm.value.totalPayableByCustomer = 0;
      dataForm.value.totalPayableByInsurer = 0;

      let acumulatorTotalPayableByInsurer = 0;
      arrayOfProducts.value.forEach((product) => {
        const percentageTheDiscount = percentage(product.insuranceDiscount);
        const discountForInsurer = product.total * percentageTheDiscount;
        acumulatorTotalPayableByInsurer += discountForInsurer;
      });

      dataForm.value.totalPayableByInsurer = parseFloat(
        acumulatorTotalPayableByInsurer.toFixed(2)
      );
      dataForm.value.totalPayableByCustomer = parseFloat(
        (dataForm.value.total - acumulatorTotalPayableByInsurer).toFixed(2)
      );

      dataForm.value.totalWhitDiscount = dataForm.value.totalPayableByCustomer;
      dataForm.value.cashAmount = dataForm.value.totalWhitDiscount;
    };

    /*********************************************************************************
     * Funcion para añadir productos a la lista de ventas
     * Los datos del producto biene desde el componente de SearchProduct
     *********************************************************************************/
    const arrayOfProducts = ref([]);
    const IDPRODUCTTEMP = ref(0);
    const addProducts = async (data) => {
      let {
        discount,
        expired,
        idProduct,
        igv,
        tipo,
        igvApplied,
        insuranceDiscount,
        maximumDiscount,
        poster,
        productName,
        quantity,
        salePrice,
        subTotal,
        taxFree,
        total,
        unitMeasure,
        units,
        quantityContained,
        precio_descuento,
        vencimiento_descuento,
        productSerie,
        warranty_expiration,
        warranty_type,
        shoppingPrice,
        priceSelectedForTheCustomer,
        formBarcode,
      } = data;
      //parseamos el iva del producto
      const CONFIGURE_IGV = percentage(igvApplied);

      subTotal = parseFloat(
        (parseFloat(salePrice) / (CONFIGURE_IGV + 1)).toFixed(2)
      );
      igv = parseFloat((parseFloat(subTotal) * CONFIGURE_IGV).toFixed(2));
      total = parseFloat(
        (parseFloat(quantity) * parseFloat(salePrice)).toFixed(2)
      );

      // Buscar el producto en la lista existente
      const existingProductIndex = arrayOfProducts.value.findIndex(
        (order) =>
          order.idProduct === idProduct && order.unitMeasure === unitMeasure
      );

      if (existingProductIndex !== -1) {
        // El producto ya existe en la lista, sumar la cantidad y actualizar los precios
        const existingProduct = arrayOfProducts.value[existingProductIndex];
        const newQuantity = existingProduct.quantity + quantity;

        const thereIsStock = await validateStockProduct(idProduct, newQuantity);

        if (thereIsStock) {
          existingProduct.quantity = newQuantity;
          existingProduct.subTotal = parseFloat(
            (subTotal * newQuantity).toFixed(2)
          );
          existingProduct.igv = parseFloat(
            (existingProduct.subTotal * CONFIGURE_IGV).toFixed(2)
          );
          existingProduct.total = parseFloat(
            (existingProduct.quantity * parseFloat(salePrice)).toFixed(2)
          );
        } else {
          errorActions(
            "Lo sentimos, no pudimos agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
          );
        }
      } else {
        IDPRODUCTTEMP.value++;
        const orderData = {
          id: IDPRODUCTTEMP.value,
          discount,
          expired,
          idProduct,
          igv,
          tipo,
          igvApplied,
          insuranceDiscount,
          maximumDiscount,
          poster,
          productName,
          quantity,
          salePrice: parseFloat(salePrice),
          subTotal,
          taxFree,
          total,
          unitMeasure,
          units,
          quantityContained,
          precio_descuento: parseFloat(precio_descuento),
          vencimiento_descuento,
          productSerie,
          warranty_expiration,
          warranty_type,
          shoppingPrice,
          showBTNPriceTwo: data.showBTNPriceTwo,
          showBTNPriceThree: data.showBTNPriceThree,
          priceSelectedForTheCustomer,
        };
        arrayOfProducts.value.push(orderData);
      }
      await nextTick();
      if (!formBarcode) {
        const trFocus = document.querySelectorAll(".td-focus");
        if (trFocus.length > 0) {
          const tdFocus = trFocus[0].querySelectorAll("td");
          if (tdFocus.length > 2) {
            const input = tdFocus[2].querySelector("input");
            if (input) {
              await nextTick();
              input.focus();
            } else {
              console.log("No se encontró el input en el tercer <td>.");
            }
          } else {
            console.log(
              "No hay suficientes <td> en el primer tr con la clase .td-focus."
            );
          }
        } else {
          console.log("No se encontraron elementos con la clase .td-focus.");
        }
      }

      calculateTheTotal(arrayOfProducts.value);
      //console.log(arrayOfProducts.value);
    };

    /*********************************************************************************
     * Funcion para eliminar un registro del arreglo de la orden
     *********************************************************************************/
    const deleteRecordFromTable = (index) => {
      arrayOfProducts.value.splice(index, 1);
      //Llamamos la funcion para calcular la orden  y le pasamos como parametro el detalle de la orden
      calculateTheTotal(arrayOfProducts.value);
    };

    /*********************************************************************************
     * Funcion para calcular el total de la orden, incluyendo el igv, subtotal y total
     *********************************************************************************/
    const calculateTheTotal = (order) => {
      //ORDERNAR EL ARRAY DE FORMA DESCENDETE
      arrayOfProducts.value.sort((a, b) => b.id - a.id);
      //Nos aseguramos que la variables se inicialicen en cero
      dataForm.value.total = 0;
      dataForm.value.subTotal = 0;
      dataForm.value.igv = 0;

      const DISCOUNT = percentage(dataForm.value.discount);

      //Recorremos todo el detalle de la orden
      order.forEach((element) => {
        let total = 0;
        let subTotal = 0;
        let igv = 0;
        //EL monto de descuento a descontar al total
        const totalDiscount = element.total * DISCOUNT;
        //parseamos el iva del producto
        const CONFIGURE_IGV = percentage(element.igvApplied);

        total = parseFloat(
          (parseFloat(element.total) - totalDiscount).toFixed(2)
        );
        subTotal = parseFloat((total / (CONFIGURE_IGV + 1)).toFixed(2));
        igv = parseFloat((parseFloat(subTotal) * CONFIGURE_IGV).toFixed(2));
        dataForm.value.total += total;
        dataForm.value.subTotal += subTotal;
        dataForm.value.igv += igv;
      });

      calculateAmountPaymentCustomer();
      // console.log(arrayOfProducts.value);
    };

    /*********************************************************************************
     * Funcion para validar cuanto va a pagar el cliente
     *********************************************************************************/

    const calculateAmountPaymentCustomer = () => {
      dataForm.value.totalWhitDiscount = dataForm.value.total; //Total que debe de pagar el cliente
      if (dataForm.value.otherDiscounts > 0) {
        dataForm.value.totalWhitDiscount =
          dataForm.value.total - dataForm.value.otherDiscounts;
      }

      dataForm.value.cashAmount = dataForm.value.totalWhitDiscount;
      if (dataForm.value.totalCreditNote > 0) {
        dataForm.value.totalWhitDiscount =
          dataForm.value.totalCreditNoteDiference -
          dataForm.value.otherDiscounts;
      }
      dataForm.value.cashAmount = dataForm.value.totalWhitDiscount;
    };
    /*********************************************************************************
     * Funcion para cambiar la cantidad de la orden
     *********************************************************************************/
    const handleQuantityChange = async (index, event, igvApplied) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;

      const quantityForValidate =
        newQuantity * arrayOfProducts.value[index].quantityContained;

      const tipo = arrayOfProducts.value[index].tipo;
      if (
        nameVoucher.value.trim() !== "COTIZACIÓN" &&
        nameVoucher.value.trim() !== "COTIZACION" &&
        nameVoucher.value.trim() !== "COTIZACIONES" &&
        tipo !== 0
      ) {
        const thereIsStock = await validateStockProduct(
          arrayOfProducts.value[index].idProduct,
          quantityForValidate
        );
        if (thereIsStock) {
          arrayOfProducts.value[index].quantity = newQuantity;
          updateDetailData(index, igvApplied);
        } else {
          errorActions(
            "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
          );
          event.target.value = arrayOfProducts.value[index].quantity;
          return;
        }
      } else {
        arrayOfProducts.value[index].quantity = newQuantity;
        updateDetailData(index, igvApplied);
      }
    };

    /*********************************************************************************
     * Funcion para cambiar la cantidad de la orden
     *********************************************************************************/
    const handlePriceForDiscount = async (index, event, igvApplied) => {
      let newPrice = 0;
      if (event.target.checked) {
        newPrice = parseFloat(arrayOfProducts.value[index].precio_descuento);
        arrayOfProducts.value[index].precio_descuento = parseFloat(
          arrayOfProducts.value[index].salePrice
        );
      } else {
        arrayOfProducts.value[index].salePrice = parseFloat(
          arrayOfProducts.value[index].precio_descuento
        );
        newPrice = parseFloat(arrayOfProducts.value[index].salePrice);
      }

      arrayOfProducts.value[index].salePrice = newPrice;

      updateDetailData(index, igvApplied);
    };
    /*********************************************************************************
     * Funcion para cambiar la cantidad de la orden
     *********************************************************************************/
    const handleProductSerie = async (index, event) => {
      arrayOfProducts.value[index].productSerie = event.target.value;
    };
    /*********************************************************************************
     * Funcion para actualizar la orden
     *********************************************************************************/
    const updateDetailData = (index, igvApplied) => {
      const order = arrayOfProducts.value[index];

      const totalOrder = order.quantity * order.salePrice;

      //Conversion de descuento a un formato aplicable
      const DISCOUNT = percentage(order.discount);
      const CONFIGURE_IGV = percentage(igvApplied);

      const discountAmount = parseFloat((totalOrder * DISCOUNT).toFixed(2));

      const newTotal = totalOrder - discountAmount;
      const newSubTotal = newTotal / (CONFIGURE_IGV + 1);
      const newIgv = newSubTotal * CONFIGURE_IGV;

      //Volvemos a calcular el total  del producto seleccionado
      order.subTotal = parseFloat(newSubTotal.toFixed(2));
      order.igv = parseFloat(newIgv.toFixed(2));
      order.total = parseFloat(newTotal.toFixed(2));

      calculateTheTotal(arrayOfProducts.value);
    };

    /*********************************************************************************
     * Funcion para aplicar el descuento por seguro a cada producto
     *********************************************************************************/
    const handleInsuranceDiscountChange = (index, event) => {
      const newInsuranceDiscount =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;
      arrayOfProducts.value[index].insuranceDiscount = newInsuranceDiscount;
    };

    /*********************************************************************************
     * Funcion para aplicar el descuento al producto producto
     *********************************************************************************/
    const handleDiscountChange = (index, event, igvApplied) => {
      const newDiscount =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;
      if (newDiscount > arrayOfProducts.value[index].maximumDiscount) {
        errorActions(
          `El descuento que usted puede aplicar a este producto debe de ser menor o igual al <strong>${arrayOfProducts.value[index].maximumDiscount}%</strong>`
        );
        event.target.value = 0;
        return;
      }
      arrayOfProducts.value[index].discount = newDiscount;
      updateDetailData(index, igvApplied);
    };
    /*********************************************************************************
     * Funcion para modificar el precio de venta del producto
     *********************************************************************************/
    const handleUnitPriceChange = (
      index,
      event,
      igvApplied,
      shoppingPrice,
      salePrice
    ) => {
      const newPrice =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;
      if (newPrice <= parseFloat(shoppingPrice)) {
        errorActions(
          `Lo sentimos, no podemos modificar el precio de venta  <strong>debido que que es igual o menor al precio de compra</strong>`
        );
        event.target.value = salePrice;
        return;
      }
      arrayOfProducts.value[index].salePrice = newPrice;
      updateDetailData(index, igvApplied);
      //console.log(arrayOfProducts.value)
    };

    /*********************************************************************************
     * Funcion para aplicar el descuento al producto producto
     *********************************************************************************/
    const handleUnitMeasureChange = async (index, event, igvApplied) => {
      const [price, quantityContained, unitMeasure] =
        event.target.value.split("|");
      //Calculamos la cantidad de productos
      const quantity =
        arrayOfProducts.value[index].quantity * parseFloat(quantityContained);

      //OBTENCION DE LAS UNIDADES DE MEDIDA
      const selectUM = arrayOfProducts.value[index].units.filter(
        (item) => item.unidad_de_medida === unitMeasure
      );

      if (
        nameVoucher.value.trim() !== "COTIZACIÓN" &&
        nameVoucher.value.trim() !== "COTIZACION" &&
        nameVoucher.value.trim() !== "COTIZACIONES"
      ) {
        //Validamos que el producto cuente con stock suficiente para ser cambiado de unidad de medida
        const thereIsStock = await validateStockProduct(
          arrayOfProducts.value[index].idProduct,
          quantity
        );

        if (thereIsStock) {
          arrayOfProducts.value[index].salePrice = price;
          arrayOfProducts.value[index].unitMeasure = unitMeasure;
          arrayOfProducts.value[index].quantityContained =
            parseFloat(quantityContained);

          if (selectUM[0].precio_dos > 0) {
            arrayOfProducts.value[index].showBTNPriceTwo = true;
          } else {
            arrayOfProducts.value[index].showBTNPriceTwo = false;
          }
          if (selectUM[0].precio_tres > 0) {
            arrayOfProducts.value[index].showBTNPriceThree = true;
          } else {
            arrayOfProducts.value[index].showBTNPriceThree = false;
          }
          updateDetailData(index, igvApplied);
        } else {
          errorActions(
            "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
          );
          event.target.selectedIndex = 0;
          return;
        }
      } else {
        arrayOfProducts.value[index].salePrice = price;
        arrayOfProducts.value[index].unitMeasure = unitMeasure;
        arrayOfProducts.value[index].quantityContained =
          parseFloat(quantityContained);

        if (selectUM[0].precio_dos > 0) {
          arrayOfProducts.value[index].showBTNPriceTwo = true;
        } else {
          arrayOfProducts.value[index].showBTNPriceTwo = false;
        }
        if (selectUM[0].precio_tres > 0) {
          arrayOfProducts.value[index].showBTNPriceThree = true;
        } else {
          arrayOfProducts.value[index].showBTNPriceThree = false;
        }
        updateDetailData(index, igvApplied);
      }
      arrayOfProducts.value[index].priceSelectedForTheCustomer = 1;
    };

    /*********************************************************************************
     * Funcion para cambiar el precio del producto por PRECIO 1, PRECIO 2, PRECIO 3
     *********************************************************************************/
    const handlePrices = async (index, event, product) => {
      const price = parseInt(event.target.value);
      const unitMeasure = arrayOfProducts.value[index].unitMeasure;
      const selectUM = product.units.filter(
        (item) => item.unidad_de_medida === unitMeasure
      );
      if (selectUM && selectUM.length > 0) {
        if (price === 1) {
          arrayOfProducts.value[index].salePrice = selectUM[0].precio;
        } else if (price === 2) {
          if (parseFloat(selectUM[0].precio_dos) > 0) {
            arrayOfProducts.value[index].salePrice = selectUM[0].precio_dos;
          }
        } else if (price === 3) {
          if (parseFloat(selectUM[0].precio_dos) > 0) {
            arrayOfProducts.value[index].salePrice = selectUM[0].precio_tres;
          }
        }
        arrayOfProducts.value[index].priceSelectedForTheCustomer = price;
        updateDetailData(index, product.igvApplied);
      }
    };

    /*********************************************************************************
     * Funcion para validar el stock del producto
     *********************************************************************************/
    const validateStockProduct = async (id, stock) => {
      try {
        const response = await axios.get(
          `productos-para-venta/${id}?stock=${stock}`,
          {
            methods: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.data;
        if (data === true) return true;
        return false;
      } catch (error) {
        errorActions(error);
        return false;
      }
    };

    /*********************************************************************************
     * Funcion aplicar descuento a la venta
     *********************************************************************************/
    const applyDiscountToSale = async () => {
      calculateTheTotal(arrayOfProducts.value);

      const amountDiscount =
        dataForm.value.discount === null || dataForm.value.discount === ""
          ? 0
          : dataForm.value.discount;
      const discount = percentage(limitDiscount.value);
      const discountCustomer = percentage(infoCustomer.value.maximunDiscount);
      let discountTheform = percentage(amountDiscount);
      if (discountTheform > discountCustomer) {
        errorActions(
          `El descuento máximo que puedes aplicar al cliente <strong>${infoCustomer.value.fullName}</strong> debe de ser: <br> <strong> menor o igual al ${infoCustomer.value.maximunDiscount}%</strong>`
        );
        dataForm.value.discount = 0;
        discountTheform = 0;
      }

      if (discountTheform > discount) {
        errorActions(
          `Lo sentimos usted tiene permitido reazalir un descuento <br> <strong> menor o igual al ${limitDiscount.value}%</strong>`
        );
        dataForm.value.discount = 0;
        discountTheform = 0;
      }
      // const amountForDiscount = dataForm.value.total * discountTheform;
      // dataForm.value.total = dataForm.value.total - amountForDiscount;

      // dataForm.value.subTotal = parseFloat(
      //   (parseFloat(dataForm.value.total) / TO_DIVIDE).toFixed(2)
      // );
      // dataForm.value.igv = parseFloat(
      //   (parseFloat(dataForm.value.subTotal) * TO_MULTIPLY).toFixed(2)
      // );

      calculateTheTotal(arrayOfProducts.value);
    };

    //recepcion de datos desde el componente publico
    const getSelectTypeOfPayment = (data) => {
      dataForm.value.idTypeOfPayment = data;
      if (data === 1 || data === 5) {
        stateForPaymentType.value = false;
      } else {
        stateForPaymentType.value = true;
      }
      if (data === 5) {
        dataForm.value.cashAmount = 0;
        showCombinedPayments();
      }
      if (data !== 1) {
        activeInputForPaymentCustomer(false);
      } else {
        activeInputForPaymentCustomer(true);
        dataForm.value.cashAmount = dataForm.value.totalWhitDiscount;
      }
    };
    const getSelectTypeOfSale = (data) => {
      dataForm.value.idTypeOfSale = data;
      if (data == 1) {
        stateForSaleType.value = false;
        enableAndDisabledSelects(false);
        activeInputForPaymentCustomer(true);
        stateForCreditToSale.value = false;
        calculateAmountPaymentCustomer();
        dataForm.value.paymentDate = null;
      } else {
        stateForSaleType.value = true;
        dataForm.value.cashAmount = 0;
        enableAndDisabledSelects(true);
        activeInputForPaymentCustomer(false);
        stateForCreditToSale.value = true;
        dataForm.value.cashAmount = 0;
        dataForm.value.paymentDate = infoCustomer.value.paydays;
      }
    };

    const activeInputForPaymentCustomer = (state) => {
      stateInputsThePayment.value = state;
      dataForm.value.customerAmountPaid = 0;
      dataForm.value.customerAmountReturned = 0;
    };
    const enableAndDisabledSelects = (state) => {
      document.getElementById("idTypeOfPayment").disabled = state;
      dataForm.value.paymentDate = null;
      dataForm.value.initialFee = 0;
    };
    const typeVoucher = ref(null);
    const getSelectVouchersOfSale = async (data) => {
      dataForm.value.idVoucher = data.id;
      nameVoucher.value = data.name;
      if (parseInt(infoCustomer.value.fixed_voucher) <= 0) {
        typeVoucher.value = null;
      }
      if (
        data.name.trim() === "COTIZACION" ||
        data.name.trim() === "COTIZACIÓN" ||
        data.name.trim() === "COTIZACIONES"
      ) {
        stateForQuotation.value = true;
        stateInputsThePayment.value = false;
      } else if (
        data.name.trim() === "PEDIDO" ||
        data.name.trim() === "PEDIDO CLIENTE" ||
        data.name.trim() === "FACTURA HOLD" ||
        data.name.trim() === "FACTURAS HOLD" ||
        data.name.trim() === "FACTURA OLD" ||
        data.name.trim() === "FACTURAS OLD"
      ) {
        stateInputsThePayment.value = false;
        stateForQuotation.value = false;
      } else {
        stateForQuotation.value = false;
        dataForm.value.expireQuotation = null;
        stateInputsThePayment.value = true;
      }
      if (parseInt(infoCustomer.value.fixed_voucher) > 0) {
        await nextTick();
        nameVoucher.value = data.name;
      }

      if (
        nameVoucher.value !== "PEDIDO CLIENTE" &&
        nameVoucher.value !== "COTIZACION"
      ) {
        activeInputForPaymentCustomer(true);
      } else {
        activeInputForPaymentCustomer(false);
      }
    };

    const showTemporarySales = async () => {
      if (VALIDATEDACCES.value.includes("VENTAS TEMPORALES MODULO VENTAS")) {
        stateTemporarySales.value = !stateTemporarySales.value;
        stateSalesHold.value = false;
        stateAppDelivery.value = false;
        stateInsurer.value = false;
        stateQuotes.value = false;
        stateCreditNote.value = false;
      } else {
        errorActions(
          "Lo sentimos no podemos aperturar esta funcionalidad debido a que <strong>no tienes el acceso permitido a este modulo</strong>"
        );
      }
    };
    const showSalesHold = async () => {
      if (VALIDATEDACCES.value.includes("FACTURAS HOLD MODULO VENTAS")) {
        stateSalesHold.value = !stateSalesHold.value;
        if (stateSalesHold.value) {
          typeVoucher.value = "FACTURA PARA CONSUMO";
        } else {
          typeVoucher.value = null;
        }
        stateTemporarySales.value = false;
        stateAppDelivery.value = false;
        stateInsurer.value = false;
        stateQuotes.value = false;
        stateCreditNote.value = false;
      } else {
        errorActions(
          "Lo sentimos no podemos aperturar esta funcionalidad debido a que <strong>no tienes el acceso permitido a este modulo</strong>"
        );
      }
      changeVoucher.value++;
    };
    const showAppDelivery = () => {
      stateAppDelivery.value = !stateAppDelivery.value;
      stateSalesHold.value = false;
      stateTemporarySales.value = false;
      stateInsurer.value = false;
      stateQuotes.value = false;
      stateCreditNote.value = false;
    };
    const showInsurer = () => {
      stateInsurer.value = !stateInsurer.value;
      stateSalesHold.value = false;
      stateTemporarySales.value = false;
      stateAppDelivery.value = false;
      stateQuotes.value = false;
      stateCreditNote.value = false;
    };
    const showAddProduct = async () => {
      if (VALIDATEDACCES.value.includes("AGREGAR PRODUCTOS DESDE VENTAS")) {
        stateAddProduct.value = !stateAddProduct.value;
      } else {
        errorActions(
          "Lo sentimos no podemos aperturar esta funcionalidad debido a que <strong>no tienes el acceso permitido a este modulo</strong>"
        );
      }
    };
    const showQuotes = async () => {
      if (VALIDATEDACCES.value.includes("COTIZACIONES MODULO VENTAS")) {
        stateQuotes.value = !stateQuotes.value;
        stateSalesHold.value = false;
        stateTemporarySales.value = false;
        stateAppDelivery.value = false;
        stateInsurer.value = false;
        stateCreditNote.value = false;
      } else {
        errorActions(
          "Lo sentimos no podemos aperturar esta funcionalidad debido a que <strong>no tienes el acceso permitido a este modulo</strong>"
        );
      }
    };
    const showCreditNote = async () => {
      if (
        VALIDATEDACCES.value.includes("APLICAR NOTA DE CREDITO MODULO VENTAS")
      ) {
        stateCreditNote.value = !stateCreditNote.value;
        stateSalesHold.value = false;
        stateTemporarySales.value = false;
        stateAppDelivery.value = false;
        stateInsurer.value = false;
        stateQuotes.value = false;
      } else {
        errorActions(
          "Lo sentimos no podemos aperturar esta funcionalidad debido a que <strong>no tienes el acceso permitido a este modulo</strong>"
        );
      }
    };
    const getAmountAgreed = (data) => {
      dataForm.value.otherDiscounts += parseFloat(data);
    };
    const showValidateUserAccess = (areas) => {
      stateShowUserAccess.value = !stateShowUserAccess.value;
      area.value = areas;
      document.getElementById("discountSale").setAttribute("readonly", true);
    };
    const showCombinedPayments = () => {
      stateShowCombinedPayment.value = !stateShowCombinedPayment.value;
    };

    /*********************************************************************************
     * Funcion para validar si el usuario tiene acceso al modulo especifico del sistema
     *********************************************************************************/
    const validateAccess = async (data) => {
      salesDiscountStatus.value = data.state;
      limitDiscount.value = data.discount;
    };

    const counterKeyTypeOfSale = ref(0);
    const counterKeyTypeOfPayment = ref(0);
    const cancelSale = async () => {
      document.getElementById("inputCustomer").value = "";
      arrayOfProducts.value = [];
      stateForSaleType.value = false;
      stateForPaymentType.value = false;
      spinner.value = false;
      stateTemporarySales.value = false;
      stateSalesHold.value = false;
      stateAppDelivery.value = false;
      loading.value = false;
      stateAddProduct.value = false;
      stateInsurer.value = false;
      salesDiscountStatus.value = false;
      stateShowUserAccess.value = false;
      stateShowCombinedPayment.value = false;
      showQuotes.value = false;
      area.value = "";
      limitDiscount.value = 0;
      stateInputsThePayment.value = true;
      if (
        nameVoucher.value.trim() === "COTIZACIÓN" ||
        nameVoucher.value.trim() === "COTIZACION" ||
        nameVoucher.value.trim() === "COTIZACIONES"
      ) {
        stateForQuotation.value = true;
        stateInputsThePayment.value = false;
      }
      if (
        nameVoucher.value.trim() === "PEDIDO" ||
        nameVoucher.value.trim() === "PEDIDO CLIENTE" ||
        nameVoucher.value.trim() === "FACTURA HOLD" ||
        nameVoucher.value.trim() === "FACTURAS HOLD" ||
        nameVoucher.value.trim() === "FACTURA OLD" ||
        nameVoucher.value.trim() === "FACTURAS OLD"
      ) {
        stateInputsThePayment.value = false;
        stateForQuotation.value = false;
      }

      if (dataForm.value.idTypeOfSale !== 1) {
        stateForQuotation.value = true;
        stateInputsThePayment.value = false;
      }

      dataForm.value.idCreditNote = null;
      dataForm.value.total = 0;
      dataForm.value.totalWhitDiscount = 0;
      dataForm.value.igv = 0;
      dataForm.value.subTotal = 0;
      dataForm.value.otherDiscounts = 0;
      dataForm.value.totalCreditNote = 0;
      dataForm.value.totalCreditNoteDiference = 0;
      dataForm.value.discount = 0;
      dataForm.value.expireQuotation = null;
      dataForm.value.comentary = "";
      dataForm.value.totalPayableByInsurer = 0;
      dataForm.value.totalPayableByCustomer = 0;
      dataForm.value.customerName = "";
      dataForm.value.serieVoucher = "";
      dataForm.value.numberVoucher = "";
      dataForm.value.numberReference = "";
      dataForm.value.retention = 0;
      dataForm.value.entityBank = "";
      dataForm.value.numberOperation = "";
      dataForm.value.cashAmount = 0;
      dataForm.value.targetAmount = 0;
      dataForm.value.transferAmount = 0;
      dataForm.value.chequeAmount = 0;
      dataForm.value.customerAmountPaid = 0;
      dataForm.value.customerAmountReturned = 0;
      dataForm.value.modificationDate = null;
      dataForm.value.sunatAnswer = 0;
      dataForm.value.state = "";
      dataForm.value.paymentDate = null;
      dataForm.value.initialFee = 0;
      dataForm.value.idCharge = null;
      dataForm.value.nameCharge = null;
      infoCustomer.value.id = 0;
      infoCustomer.value.sell_with = 1;
      infoCustomer.value.fixed_voucher = 0;
      infoCustomer.value.paydays = null;
      IDPRODUCTTEMP.value = 0;
      infoInsurer.value.id = 0;
      stateShowInfoCustomer.value = false;
      infoOrders.value.id = 0;
      infoCreditNote.value.id = 0;
      infoCreditNote.value.amount = 0;
      stateCreditNote.value = false;

      dataForm.value.userName = await localUserName();

      counterKeyTypeOfPayment.value++;
      counterKeyTypeOfSale.value++;
      changeVoucher.value++;

      arraySelectProduct.value = [];
    };

    /*********************************************************************************
     * Funcion obtener el valor de lo multiples pagos
     *********************************************************************************/
    const getCombinedPayment = (data) => {
      const { cashAmount, targetAmount, transferAmount, chequeAmount } = data;
      dataForm.value.cashAmount = cashAmount;
      dataForm.value.targetAmount = targetAmount;
      dataForm.value.transferAmount = transferAmount;
      dataForm.value.chequeAmount = chequeAmount;
    };

    /*********************************************************************************
     * Funcion para calcular el vuelto del cliente si el tiepo de pago el efectivo
     *********************************************************************************/
    const calculteCustomerAmountReturned = () => {
      dataForm.value.customerAmountReturned = (
        dataForm.value.customerAmountPaid - dataForm.value.totalWhitDiscount
      ).toFixed(2);
    };

    /*********************************************************************************
     * Funcion para traer el nombre del cliente
     *********************************************************************************/
    const getFullNameCustomer = async () => {
      dataForm.value.customerName = document
        .getElementById("inputCustomer")
        .value.trim();
    };

    /*********************************************************************************
     * Funcion traer los datos de un pedido, cotizacion o venta temporal
     *********************************************************************************/
    const infoOrders = ref({
      id: 0,
    });
    const getOrder = async (data) => {
      infoCustomer.value.id = data.idCustomer;
      infoCustomer.value.fullName = data.nameCustomer;
      dataForm.value.comentary = data.comentary;
      dataForm.value.userName = data.userName;
      infoOrders.value.id = data.id;
      document.getElementById("inputCustomer").value = data.nameCustomer;

      if (
        nameVoucher.value === "REGIMENES ESPECIAL" ||
        nameVoucher.value === "REGIMENES ESPECIALES" ||
        nameVoucher.value === "FACTURA" ||
        nameVoucher.value === "REGÍMENES ESPECIALES" ||
        nameVoucher.value === "REGIMEN ESPECIAL"
      ) {
        arrayOfProducts.value = data.detailOrder.map((item) => ({
          ...item,
          igv: 0,
          igvApplied: 0,
          id: IDPRODUCTTEMP.value++,
        }));
      } else {
        arrayOfProducts.value = data.detailOrder.map((item) => ({
          ...item,
          id: IDPRODUCTTEMP.value++,
        }));
        arrayOfProducts.value.sort((a, b) => b.id - a.id);
      }

      if ("idVoucher" in data) {
        infoCustomer.value.fixed_voucher = data.idVoucher;
        changeVoucher.value++;
      }

      calculateTheTotal(arrayOfProducts.value);

      if (parseInt(data.customer.id) > 1) {
        infoCustomer.value.maximunDiscount =
          data.customer.descuento_limite_asignado;
        infoCustomer.value.creditLimit = parseFloat(
          data.customer.limite_de_credito
        );
        infoCustomer.value.type = data.customer.tipo_de_customer;
        infoCustomer.value.document_number = data.customer.numero_documento;
        if (parseInt(data.customer.paydays) > 0) {
          infoCustomer.value.paydays = calculatePaymentDate(
            data.customer.paydays
          );
          dataForm.value.paymentDate = infoCustomer.value.paydays;
        }
      }
    };

    /*********************************************************************************
     * Funcion para validar si un producto tiene un descuento por seguro
     *********************************************************************************/
    const validateStateForInsurer = async () => {
      arrayOfProducts.value.forEach((data) => {
        if (data.insuranceDiscount > 0) {
          return true;
        }
      });
      return false;
    };

    /*********************************************************************************
     * Validamos los tipos de pagos y asignamos sus respectivos monto siempre en
     * cuando sea EFECTIVO, CHEQUE, TRANSFERENCIA O TARJETA
     *********************************************************************************/
    const assignAmountToPaymentType = async () => {
      if (
        dataForm.value.idTypeOfPayment === 1 ||
        dataForm.value.idTypeOfPayment === 2 ||
        dataForm.value.idTypeOfPayment === 3 ||
        dataForm.value.idTypeOfPayment === 4
      ) {
        dataForm.value.cashAmount = 0;
        dataForm.value.targetAmount = 0;
        dataForm.value.transferAmount = 0;
        dataForm.value.chequeAmount = 0;
        if (dataForm.value.idTypeOfPayment === 1) {
          dataForm.value.cashAmount = dataForm.value.totalWhitDiscount;
        } else if (dataForm.value.idTypeOfPayment === 2) {
          dataForm.value.targetAmount = dataForm.value.totalWhitDiscount;
        } else if (dataForm.value.idTypeOfPayment === 3) {
          dataForm.value.transferAmount = dataForm.value.totalWhitDiscount;
        } else if (dataForm.value.idTypeOfPayment === 4) {
          dataForm.value.chequeAmount = dataForm.value.totalWhitDiscount;
        }
      }
    };

    /*********************************************************************************
     * Funcion para traer la deuda total del cliente
     *********************************************************************************/
    const checkTotalCustomerCredit = async () => {
      if (infoCustomer.value.id === 0 || infoCustomer.value.id === null) {
        errorActions(
          "Lo sentimos no podemos realizar la emisión de venta a crédito debido a que no tienes seleccionado un cliente"
        );
        spinner.value = false;
        return false;
      }
      const response = await axios
        .get("verificar-credito/" + infoCustomer.value.id)
        .catch((error) => errorActions(error));
      const data = await response.data.total;
      const totalSale =
        parseFloat(data) +
        (dataForm.value.totalWhitDiscount - dataForm.value.initialFee);

      if (totalSale > infoCustomer.value.creditLimit) {
        errorActions(
          `Lo tentimos no podemos realizar la emision de la venta a credito.
          <br> <strong>El cliente ya supera el limite de credito asignado</strong>
          <br>------------------------------- <br>
          DEUDA ANTERIOR: <strong class="text-red-500">${
            money() + data
          }</strong>
          <br>-------------------------------<br> DEUDA ANTERIOR + VENTA ACTUAL <br>
          <strong class="text-blue-500">${
            money() + totalSale.toFixed(2)
          }</strong>
          <br>-------------------------------<br> LIMITE DE CREDITO DEL CLIENTE <br>
          <strong class="text-green-500">${
            money() + infoCustomer.value.creditLimit
          }</strong>`
        );
        spinner.value = false;
        return false;
      }
      return true;
    };

    /*********************************************************************************
     * Funcion para validar el estado de la caja
     *********************************************************************************/
    const validateBoxe = async () => {
      const storage = localStorage.getItem("boxState");
      if (storage === null) {
        return false;
      } else {
        if (storage === "true") {
          return true;
        }
        return false;
      }
    };

    /*********************************************************************************
     * Funcion para insertar la venta
     *********************************************************************************/
    const typePrintVoucher = ref("");
    const insert = async () => {
      spinner.value = true;
      if (
        nameVoucher.value.trim() === "FACTURA ELECTRONICA" ||
        nameVoucher.value.trim() === "FACTURA ELECTRÓNICA" ||
        nameVoucher.value.trim() === "NOTA DE VENTA" ||
        nameVoucher.value.trim() === "BOLETA ELECTRÓNICA" ||
        nameVoucher.value.trim() === "BOLETA ELECTRONICA" ||
        nameVoucher.value.trim() === "BOLETA" ||
        nameVoucher.value.trim() === "FACTURA" ||
        nameVoucher.value.trim() === "FACTURA PARA CREDITO FISCAL" ||
        nameVoucher.value.trim() === "FACTURA PARA CONSUMO" ||
        nameVoucher.value.trim() === "COMPROBANTE GUBERNAMENTAL" ||
        nameVoucher.value.trim() === "FACTURA PROFORMA" ||
        nameVoucher.value.trim() === "PROFORMA" ||
        nameVoucher.value.trim() === "TICKET" ||
        nameVoucher.value.trim() === "PROFORMA DE VENTA" ||
        nameVoucher.value.trim() === "REGIMENES ESPECIAL" ||
        nameVoucher.value.trim() === "REGIMEN ESPECIAL" ||
        nameVoucher.value.trim() === "REGIMENES ESPECIALES" ||
        nameVoucher.value.trim() === "REGÍMENES ESPECIALES" ||
        nameVoucher.value.trim() === "COTIZACIÓN" ||
        nameVoucher.value.trim() === "COTIZACION" ||
        nameVoucher.value.trim() === "COTIZACIONES" ||
        nameVoucher.value.trim() === "PEDIDO" ||
        nameVoucher.value.trim() === "PEDIDO CLIENTE" ||
        nameVoucher.value.trim() === "FACTURA HOLD" ||
        nameVoucher.value.trim() === "FACTURAS HOLD" ||
        nameVoucher.value.trim() === "FACTURA OLD" ||
        nameVoucher.value.trim() === "FACTURAS OLD"
      ) {
        /*********************************************************************************
         * Si la venta es a credito validamos que haya ingresado le fecha de pago
         *********************************************************************************/
        if (dataForm.value.idTypeOfSale !== 1) {
          if (
            dataForm.value.paymentDate === null ||
            dataForm.value.paymentDate === undefined
          ) {
            errorActions(
              "Lo sentimos no podemos realizar la venta debido a que no has seleccionado la fecha de pago de la venta a crédito"
            );
            spinner.value = false;
            return;
          }
        }

        /*********************************************************************************
         * Llamamos al comprobante y obtenemos sus datos
         *********************************************************************************/
        const { id, numberCurrent, serie, printFormat, numberEnd } =
          await voucherSales(dataForm.value.idVoucher);

        /*********************************************************************************
         * Validamos que el numero de comprobante actual no sea mayor al numero final configurado
         *********************************************************************************/
        if (parseInt(numberCurrent) > parseInt(numberEnd)) {
          errorActions(
            `Lo sentimos no podemos generar la venta debido a que <strong class="text-red-500 font-bold">tu número de comprobante se a agotado</strong>. <br> Solicita nuevos números a tu entidad y vuelve a generar tus ventas con normalidad.`
          );
          spinner.value = false;
          return;
        }

        typePrintVoucher.value = printFormat;
        dataForm.value.idVoucher = id;
        dataForm.value.numberVoucher = numberCurrent;
        dataForm.value.serieVoucher = serie;
      }

      /*********************************************************************************
       * Validaciones obligatorias antes de realizar la venta
       *********************************************************************************/
      await getFullNameCustomer();
      await assignAmountToPaymentType();
      if (dataForm.value.customerName.length <= 2) {
        errorActions(
          "Por favor ingresa el nombre del cliente y vuelve a intentarlo de nuevo"
        );
        spinner.value = false;
        return;
      }

      if (await validateStateForInsurer()) {
        errorActions(
          "Hay un producto que tiene un descuento por seguro, por favor selecciona un aseguradora y vuelve a intentarlo"
        );
        spinner.value = false;
        return;
      }

      if (
        nameVoucher.value.trim() !== "PEDIDO" &&
        nameVoucher.value.trim() !== "PEDIDO CLIENTE" &&
        nameVoucher.value.trim() !== "FACTURA HOLD" &&
        nameVoucher.value.trim() !== "FACTURAS HOLD" &&
        nameVoucher.value.trim() !== "FACTURA OLD" &&
        nameVoucher.value.trim() !== "FACTURAS OLD" &&
        nameVoucher.value.trim() !== "COTIZACIÓN" &&
        nameVoucher.value.trim() !== "COTIZACION" &&
        nameVoucher.value.trim() !== "COTIZACIONES"
      ) {
        if (dataForm.value.idTypeOfSale === 1) {
          if (dataForm.value.idTypeOfPayment === 1) {
            if (
              dataForm.value.customerAmountPaid <
              dataForm.value.totalWhitDiscount
            ) {
              errorActions(
                "Por favor ingresa el monto con lo que esta pagando el cliente y vuelve a intentarlo de nuevo"
              );
              spinner.value = false;
              return;
            }
          }
          if (dataForm.value.idTypeOfPayment === 5) {
            const total =
              parseFloat(dataForm.value.cashAmount) +
              parseFloat(dataForm.value.targetAmount) +
              parseFloat(dataForm.value.transferAmount) +
              parseFloat(dataForm.value.chequeAmount);
            if (total < dataForm.value.totalWhitDiscount) {
              errorActions(
                "Ud tiene seleccionado la opcion de pago combinado y al parecer no a ingresado ningun valor, por favor seleccione nuevamente el tipo de pago e ingrese su monto."
              );
              spinner.value = false;
              return;
            }
          }
        } else {
          //Mandamos a llamar la funcion para saber si el cliente aun puede hacer un compra a credito
          if (!(await checkTotalCustomerCredit())) {
            return;
          }
          dataForm.value.cashAmount = 0;
        }
      }
      if (arrayOfProducts.value.length <= 0) {
        errorActions(
          "Por favor agrega unos productos a la lista de ventas y vuelve a intentarlo de nuevo."
        );
        spinner.value = false;
        return;
      }

      /*********************************************************************************
       * Insertar la venta
       *********************************************************************************/
      if (
        nameVoucher.value.trim() === "FACTURA ELECTRONICA" ||
        nameVoucher.value.trim() === "FACTURA ELECTRÓNICA" ||
        nameVoucher.value.trim() === "NOTA DE VENTA" ||
        nameVoucher.value.trim() === "BOLETA ELECTRÓNICA" ||
        nameVoucher.value.trim() === "BOLETA ELECTRONICA" ||
        nameVoucher.value.trim() === "BOLETA" ||
        nameVoucher.value.trim() === "FACTURA" ||
        nameVoucher.value.trim() === "FACTURA PARA CREDITO FISCAL" ||
        nameVoucher.value.trim() === "FACTURA PARA CONSUMO" ||
        nameVoucher.value.trim() === "COMPROBANTE GUBERNAMENTAL" ||
        nameVoucher.value.trim() === "FACTURA PROFORMA" ||
        nameVoucher.value.trim() === "PROFORMA" ||
        nameVoucher.value.trim() === "TICKET" ||
        nameVoucher.value.trim() === "PROFORMA DE VENTA" ||
        nameVoucher.value.trim() === "REGIMENES ESPECIAL" ||
        nameVoucher.value.trim() === "REGIMENES ESPECIALES" ||
        nameVoucher.value.trim() === "REGÍMENES ESPECIALES" ||
        nameVoucher.value.trim() === "REGIMEN ESPECIAL"
      ) {
        /*********************************************************************************
         * Antes de llamar al numero del comprobante, general la venta, etc.
         * Tenemos que verificar el estado de la caja
         *********************************************************************************/
        const stateBox = await validateBoxe();
        if (stateBox === false) {
          errorActions(
            "Por favor <strong>apertura la caja</strong> para poder realizar la emision de la venta"
          );
          spinner.value = false;
          return;
        }

        /*********************************************************************************
         * Llamamos al numero de referencia para obtener sus valores
         *********************************************************************************/
        const { idReferenceNumber, referenceNumberCurrent } =
          await referenceNumber();
        dataForm.value.numberReference = referenceNumberCurrent;

        //Insertamos la venta
        const salesRecord = await insertSale(
          dataForm.value,
          arrayOfProducts.value,
          infoCustomer.value,
          infoInsurer.value,
          infoOrders.value,
          infoCreditNote.value
        );
        //validamos si la venta se inserto
        if (salesRecord !== undefined || salesRecord !== null) {
          if (parseInt(salesRecord) > 0) {
            //Actualizamos el numero de referencia
            await updateReferenceNumber(
              idReferenceNumber,
              referenceNumberCurrent
            );
            //Actualizamos el numero de comprobante
            await updateNumberVoucher(
              dataForm.value.idVoucher,
              dataForm.value.numberVoucher
            );
            if (typePrintVoucher.value === "formato_a4") {
              printVoucherOnA4(salesRecord);
            } else if (typePrintVoucher.value === "formato_8x11") {
              printVoucherOnLetter(salesRecord);
            } else if (typePrintVoucher.value === "formato_media_carta") {
              printVoucherOnMediaLetter(salesRecord);
            } else if (typePrintVoucher.value === "formato_ticket") {
              printVoucherTicket(salesRecord);
            }
            //Limpiamos los imputs para la nueva venta
            await cancelSale();
            spinner.value = false;
            return; //Retornamos para no llegar a venta temporales
          } else {
            spinner.value = false;
            return; //Retornamos para no llegar a venta temporales
          }
        } else {
          spinner.value = false;
          return; //Retornamos para no llegar a venta temporales
        }
      }

      /*********************************************************************************
       * Insertar el pedido cliente
       *********************************************************************************/
      if (
        nameVoucher.value.trim() === "PEDIDO" ||
        nameVoucher.value.trim() === "PEDIDO CLIENTE" ||
        nameVoucher.value.trim() === "FACTURA HOLD" ||
        nameVoucher.value.trim() === "FACTURAS HOLD" ||
        nameVoucher.value.trim() === "FACTURA OLD" ||
        nameVoucher.value.trim() === "FACTURAS OLD"
      ) {
        const wasInsert = await insertCustomerOrder(
          dataForm.value,
          arrayOfProducts.value,
          infoCustomer.value
        );
        if (wasInsert !== undefined || wasInsert !== null) {
          //Actualizamos los datos del comprobantes
          await updateNumberVoucher(
            dataForm.value.idVoucher,
            dataForm.value.numberVoucher
          );
          //Limpiamos los imputs para la nueva venta
          await cancelSale();
          return; //Retornamos para no llegar a venta temporales
        }
        return;
      }

      /*********************************************************************************
       * Insertar la cotización del cliente
       *********************************************************************************/
      if (
        nameVoucher.value.trim() === "COTIZACIÓN" ||
        nameVoucher.value.trim() === "COTIZACION" ||
        nameVoucher.value.trim() === "COTIZACIONES"
      ) {
        const wasInsert = await insertQuotes(
          dataForm.value,
          arrayOfProducts.value,
          infoCustomer.value
        );
        if (wasInsert !== undefined || wasInsert !== null) {
          //Actualizamos los datos del comprobantes
          await updateNumberVoucher(
            dataForm.value.idVoucher,
            dataForm.value.numberVoucher
          );
          if (typePrintVoucher.value === "formato_a4") {
            printOnA4(wasInsert);
          } else if (typePrintVoucher.value === "formato_8x11") {
            printOnLetter(wasInsert);
          } else if (typePrintVoucher.value === "formato_media_carta") {
            printOnMediaLetter(wasInsert);
          } else if (typePrintVoucher.value === "formato_ticket") {
            printOnTicket(wasInsert);
          }
          //Limpiamos los imputs para la nueva venta
          await cancelSale();
          return; //Retornamos para no llegar a venta temporales
        }
        return;
      }
    };

    let handleKeydown;
    const KEYDOWN = () => {
      handleKeydown = async (event) => {
        if (event.altKey && event.key.toLowerCase() === "c") {
          event.preventDefault();
          document.getElementById("inputCustomer").focus();
        }
        if (event.altKey && event.key.toLowerCase() === "p") {
          event.preventDefault();
          document.getElementById("searchInputProduct").focus();
        }
        if (event.altKey && event.key.toLowerCase() === "x") {
          event.preventDefault();
          await cancelSale();
        }
        if (event.altKey && event.key.toLowerCase() === "s") {
          event.preventDefault();
          await insert();
        }
        if (event.altKey && event.key.toLowerCase() === "d") {
          event.preventDefault();
          const IMPORT = document.getElementById("import_payments");
          IMPORT.focus();
          IMPORT.select();
        }
      };
      document.addEventListener("keydown", handleKeydown);
      document.getElementById("searchInputProduct").focus();
    };

    onMounted(async () => {
      observeElement("#containtSales");
      observeElement("#dataTable");

      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
      dataForm.value.idTurn = await idLocalTurn();
      dataForm.value.userName = await localUserName();
      dataForm.value.igvApplied = await localIVA();
      const currency = await localMoney();
      dataForm.value.currencyDescription = currency.currency;
      dataForm.value.currencyPrefix = currency.prefix;

      VALIDATEDACCES.value = await validateAccessSpecific(
        ACCESS,
        await idLocalUser()
      );

      if (VALIDATEDACCES.value.includes("ASEGURADORAS MODULO VENTAS")) {
        stateAccesInsurer.value = true;
      }
      if (
        VALIDATEDACCES.value.includes(
          "✏ MODIFICAR PRECIO DE VENTA DEL PRODUCTO (VENTAS)"
        )
      ) {
        stateModifiPrice.value = true;
      }

      if (VALIDATEDACCES.value.includes("APLICAR PRECIO 2 EN VENTAS")) {
        usePriceTwo.value = true;
      }
      if (VALIDATEDACCES.value.includes("APLICAR PRECIO 3 EN VENTAS")) {
        usePriceThree.value = true;
      }

      if (
        VALIDATEDACCES.value.includes(
          "ACTIVAR COLUMNA DE CHECK EN LA TABLA VENTAS"
        )
      ) {
        stateAccesCheck.value = true;
      }
      if (
        VALIDATEDACCES.value.includes(
          "ACTIVAR COLUMNA DE SERIE DE PRODUCTO TABLA DE VENTAS"
        )
      ) {
        stateAccesPSerie.value = true;
      }
      header.value = columsHidden(
        stateAccesInsurer.value,
        stateAccesCheck.value,
        stateAccesPSerie.value
      );

      adjustTableSize();
      KEYDOWN();
    });
    onUnmounted(() => {
      document.removeEventListener("keydown", handleKeydown);
    });

    /*********************************************************************************
     * Funcion para guardar un venta temporal
     *********************************************************************************/
    const saveTemporarySales = async () => {
      /*********************************************************************************
       * Validaciones obligatorias antes de realizar la venta
       *********************************************************************************/
      await getFullNameCustomer();
      if (dataForm.value.customerName.length <= 2) {
        errorActions(
          "Por favor ingresa el nombre del cliente y vuelve a intentarlo de nuevo"
        );
        spinner.value = false;
        return;
      }
      if (arrayOfProducts.value.length <= 0) {
        errorActions(
          "Por favor agrega unos productos a la lista de ventas y vuelve a intentarlo de nuevo."
        );
        spinner.value = false;
        return;
      }
      /*********************************************************************************
       * Insertar ventas temporales
       *********************************************************************************/
      const wasInsert = await insertTemporarySale(
        dataForm.value,
        arrayOfProducts.value,
        infoCustomer.value
      );
      if (wasInsert !== undefined || wasInsert !== null) {
        //Limpiamos los imputs para la nueva venta
        await cancelSale();
      }
    };
    const adjustTableSize = () => {
      const table = document.querySelector(".table-d");
      const menu = document.querySelector(".grid-cols-2-small");
      if (table && menu) {
        table.style.width = `${menu.offsetWidth - 40}px`;
        table.style.overflowX = "scroll";
      }
    };

    const mediaQuery = window.matchMedia("(max-width: 700px)");
    if (mediaQuery.matches) {
      adjustTableSize();
    }

    window.addEventListener("resize", () => {
      if (mediaQuery.matches) {
        adjustTableSize();
      }
    });
    return {
      getSelectTypeOfPayment,
      getSelectTypeOfSale,
      getSelectVouchersOfSale,
      dataForm,
      stateForPaymentType,
      stateForSaleType,
      stateForQuotation,
      spinner,
      showTemporarySales,
      stateTemporarySales,
      header,
      loading,
      money,
      format,
      stateSalesHold,
      showSalesHold,
      getDataCustomer,
      getDataAppDelivery,
      stateAppDelivery,
      showAppDelivery,
      addProducts,
      deleteRecordFromTable,
      arrayOfProducts,
      handleQuantityChange,
      handleInsuranceDiscountChange,
      handleDiscountChange,
      handleUnitMeasureChange,
      stateAddProduct,
      showAddProduct,
      getAmountAgreed,
      cancelSale,
      getInsurer,
      stateInsurer,
      showInsurer,
      infoInsurer,
      showValidateUserAccess,
      validateAccess,
      stateShowUserAccess,
      salesDiscountStatus,
      area,
      applyDiscountToSale,
      insert,
      showCombinedPayments,
      stateShowCombinedPayment,
      getCombinedPayment,
      calculteCustomerAmountReturned,
      stateInputsThePayment,
      stateShowInfoCustomer,
      saveTemporarySales,
      stateForCreditToSale,
      getOrder,
      showQuotes,
      stateQuotes,
      getCreditNote,
      stateCreditNote,
      showCreditNote,
      stateAccesInsurer,
      stateModifiPrice,
      nameVoucher,
      handeCharge,
      handlePriceForDiscount,
      calculateOffertExpired,
      handleProductSerie,
      handleUnitPriceChange,
      changeVoucher,
      typeVoucher,
      counterKeyTypeOfPayment,
      counterKeyTypeOfSale,
      selectProduct,
      handlePrices,
      infoCustomer,
      usePriceTwo,
      usePriceThree,
      stateAccesCheck,
      stateAccesPSerie,
    };
  },
};
</script>
<style scoped>
.scroll-options-sales::-webkit-scrollbar {
  height: 0.3rem;
  width: 0.3rem;
  border-radius: 0.5rem;
  background-color: rgb(0 0 0 /10%);
}

.scroll-options-sales::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #17223a69;
}

.scroll-options-sales::-webkit-scrollbar-thumb:active,
.scroll-options-sales::-webkit-scrollbar-thumb:hover {
  background-color: #0453ffc9;
}

.scroll-options-sales::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background-color: #49638517;
}
</style>
